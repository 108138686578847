import {
  LAYOUT_GRID_NUM_OF_COLUMNS,
  LAYOUT_GRID_NUM_OF_ROWS,
  LAYOUT_ITEM_SPACING,
  LAYOUT_RATION_SELECTED,
  LAYOUT_SELECTED,
  LAYOUT_SHOW_LOAD_MORE,
  LAYOUT_GRID_TOTAL_MEDIAS_TO_SHOW,
  LAYOUT_SLIDER_ARROW_SIZE,
  LAYOUT_SLIDER_ARROW_HORIZONTAL_PADDING,
  LAYOUT_NUM_MEDIAS_LOAD_MORE,
  IS_NEW_LAYOUT,
  LAYOUT_MOBILE_GRID_NUM_OF_COLUMNS,
  LAYOUT_MOBILE_GRID_NUM_OF_ROWS,
  LAYOUT_MOBILE_ITEM_SPACING,
  LAYOUT_MOBILE_RATION_SELECTED,
  LAYOUT_MOBILE_SELECTED,
  LAYOUT_MOBILE_SHOW_LOAD_MORE,
  LAYOUT_MOBILE_GRID_TOTAL_MEDIAS_TO_SHOW,
  LAYOUT_MOBILE_SLIDER_ARROW_SIZE,
  LAYOUT_MOBILE_SLIDER_ARROW_HORIZONTAL_PADDING,
  LAYOUT_MOBILE_NUM_MEDIAS_LOAD_MORE,
} from '../constants';

export enum PanelNames {
  ConnectAccount = 'Connect account',
  DisconnectAccount = 'Disconnect account',
  Layout = 'Layout',
  PgCustomSettings = 'Custom Pro gallery settings ',
  ExpandSettings = 'Settings',
}

export enum PgCustomOnclick {
  OpenExpand,
  NothingHappens,
  OpenLink,
}
export const CLICK_ACTION = {
  EXPAND: 'EXPAND',
  LINK: 'LINK',
  NOTHING: 'NOTHING',
};

export enum CustomSettingsPanel {
  dropdown = '#panelDropdown1',
}

export enum LayoutPanelSections {
  chooseLayout = '#panelThumbnails1',
  alignment = '#panelThumbnails2',
  imagePosition = '#panelThumbnails3',
  textTopBottomPadding = '#panelSlider1',
  textSidePadding = '#panelSlider2',
  textLineSpacing = '#panelSlider5',
  feedTopBottomPadding = '#panelSlider3',
  feedSidePadding = '#panelSlider4',
  textDivider = '#panelSectionDivider1',
  feedDivider = '#panelSectionDivider2',
  toggleImagesToShow = '#panelToggleSwitch2',
  imagesToShowNum = '#panelSlider7',
  toggleLoadMoreBtn = '#panelToggleSwitch1',
  imagesPerLoadNum = '#panelSlider6',
  customizeInLayoutPanel = '#panelRichText1',
  textBoxPadding = '#textBoxPaddingSlider',
}

export enum LayoutPanelVarNames {
  headerTopBottomPadding = 'HeaderTopBottomPadding',
  headerSidePadding = 'HeaderSidePadding',
  feedTopBottomPadding = 'FeedTopBottomPadding',
  feedSidePadding = 'FeedSidePadding',
  SideBySideContRTLstart = 'SideBySideContRTLstart',
  SideBySideContRTLend = 'SideBySideContRTLend',
  SideBySideImgRTLstart = 'SideBySideImgRTLstart',
  SideBySideImgRTLend = 'SideBySideImgRTLend',

  HeaderSpaceBetween = 'SpaceBetween',
  HeaderAlignLeft = 'OneRowAlignLeft',
  HeaderAlignRight = 'OneRowAlignRight',

  TwoRowRS = 'TwoRowRS',
  TwoRowRE = 'TwoRowRE',
  TitleCS = 'TitleCS',
  TitleCE = 'TitleCE',
  ButtonsCS = 'ButtonCS',
  ButtonsCE = 'ButtonCE',
  TitleAlignment = 'TitleAlignment',
  ButtonsAlignment = 'ButtonAlignment',
}

export enum LayoutOptions {
  Single = 'Single',
  Multi = 'Multi',
  ImagePosRight = 'Right',
  ImagePosLeft = 'Left',
  AlignRight = 'Right',
  AlignLeft = 'Left',
  AlignCenter = 'Center',
  AlignStart = 'start',
  AlignEnd = 'end',
  AlignToCenter = 'center',
}

export type LayoutFeedOptionsType =
  | typeof LAYOUT_GRID_NUM_OF_ROWS
  | typeof LAYOUT_GRID_NUM_OF_COLUMNS
  | typeof LAYOUT_ITEM_SPACING
  | typeof LAYOUT_SELECTED
  | typeof LAYOUT_RATION_SELECTED
  | typeof LAYOUT_GRID_TOTAL_MEDIAS_TO_SHOW
  | typeof LAYOUT_SLIDER_ARROW_SIZE
  | typeof LAYOUT_SLIDER_ARROW_HORIZONTAL_PADDING
  | typeof LAYOUT_NUM_MEDIAS_LOAD_MORE;

export type LayoutFeedOptionsMobileType =
  | typeof LAYOUT_MOBILE_GRID_NUM_OF_ROWS
  | typeof LAYOUT_MOBILE_GRID_NUM_OF_COLUMNS
  | typeof LAYOUT_MOBILE_ITEM_SPACING
  | typeof LAYOUT_MOBILE_SELECTED
  | typeof LAYOUT_MOBILE_RATION_SELECTED
  | typeof LAYOUT_MOBILE_GRID_TOTAL_MEDIAS_TO_SHOW
  | typeof LAYOUT_MOBILE_SLIDER_ARROW_SIZE
  | typeof LAYOUT_MOBILE_SLIDER_ARROW_HORIZONTAL_PADDING
  | typeof LAYOUT_MOBILE_NUM_MEDIAS_LOAD_MORE;

export type LayoutFeedOptionsBooleanType =
  | typeof LAYOUT_SHOW_LOAD_MORE
  | typeof IS_NEW_LAYOUT;

export type LayoutFeedOptionsBooleanMobileType =
  | typeof LAYOUT_MOBILE_SHOW_LOAD_MORE

export enum LayoutUnits {
  px = 'px',
  fr = 'fr',
}

export enum LayoutFeed {
  Grid = 2,
  Slider = 4,
}

export enum RatioOptions {
  option1 = 1.7777777777777777,
  option2 = 1.3333333333333333,
  option3 = 1,
  option4 = 0.75,
  option5 = 0.5625,
}
